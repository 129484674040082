:root {
  --main-brand: #bcd696;
}

.header {
  background-color: var(--main-brand);
  height: 10vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
}

.header-menu {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.link-menu {
  display: flex;
}

.link-menu > * {
  color: black;
  font-weight: 600;
  font-size: 24px;
  text-decoration: none;
  margin: 0 0.25rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.link-menu > *:hover {
  color: black;
  opacity: 0.5;
}

.model-table th,
td {
  text-align: center;
  vertical-align: middle;
}

.model-form {
  margin: 1rem 0;
}

.btn {
  margin: 0 0.25rem;
}

.header a {
  color: black;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
}

.header a:hover {
  color: dimgray;
}

.header a:focus {
  color: dimgray;
}

.menu-dropdown a {
  color: black;
  font-weight: 500;
  font-size: 20px;
}

.menu-dropdown a:hover {
  color: dimgray;
}

.menu-dropdown a:focus {
  color: dimgray;
}

.new-product-image {
  height: 100%;
  position: relative;
}

.new-product-image img {
  /* height: 100%;
  width: 100%; */
  object-fit: cover;
}
.new-product-image button {
  position: absolute;
  top: 40%;
}

.edit-button {
  /* position: absolute;
  right: 0;
  top: 0; */
  margin: 0px 0px 30px 0px;
}

.cm-line {
  white-space: pre-wrap !important;
}

.flex-row {
  display: flex;
}

.brand-logo {
  border-radius: 1000px;
  overflow: hidden;
  border: 1px solid black;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.brand-logo img {
  width: 100%;
}
.cursor {
  cursor: pointer;
}

.add-more-size {
  font-size: 14px !important;
}
.add-more-size img {
  margin-left: 1rem !important;
}
